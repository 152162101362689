export default {
  apiUrl: 'https://api.milkywire.com',
  apiCdnUrl: 'https://api-cdn.milkywire.com',
  host: 'https://portal.milkywire.com',
  enterpriseUrl: 'https://www.milkywire.com',
  documentationPartnerKey: 'par_cka6gvpqt00000co193mq9h2p',
  env: 'production',
  firebase: {
    apiKey: 'AIzaSyCkjMi1M7UTYXaURu70CkyYPyS3h70tlg8',
    authDomain: 'portal.milkywire.com',
    projectId: 'milkywire-voyager',
    appId: '1:9022239224:web:b50f7220cee28e6754260d',
  },
  plausible: {
    domain: 'portal.milkywire.com',
  },
  contentful: {
    spaceId: '8dzj5s79jaus',
    accessToken: 'f4397abd8e1722e334814eb0a4cbe253cfcd65619da7fa4c463794cb9d32d335',
    previewToken: '17aef4861b8b57dc624cc3ef64579cea39cd2133fbb5b5c1a51203ed66df5bca',
    env: 'master',
    apiUrl: 'https://cdn.contentful.com/spaces/8dzj5s79jaus/environments/master',
    host: undefined,
    insecure: false,
  },
  hotjar: {
    siteId: 3845820,
    hotjarVersion: 6,
  },
  feedbackSegments: {
    summary: 'fes_clv11u64000010fmg4oio9kft',
  },
};
